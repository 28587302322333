import React from "react";
import Swiper from "react-id-swiper";
import "swiper/css/swiper.css";
import techstack from "../assets/images/brands/tech-stack.svg";
import types from "../assets/images/brands/Typescript_logo_2020.svg";
import c from "../assets/images/brands/c-original.svg";
import python from "../assets/images/brands/python-original.svg";
import js from "../assets/images/brands/javascript-original.svg";
import mysql from "../assets/images/brands/mysql-original.svg";
import html5 from "../assets/images/brands/html5-original.svg";
import css3 from "../assets/images/brands/css3-originalsvg.svg";
import git from "../assets/images/brands/git-scm-icon.svg";
import nginx from "../assets/images/brands/nginx-original.svg";
import nodej from "../assets/images/brands/nodejs-original.svg";
import reactSvg from "../assets/images/brands/react-original.svg";
import gatsbySvg from "../assets/images/brands/gatsbyjs-icon-2.svg";
import nextSvg from "../assets/images/brands/nextjs-3.svg";
import graphQLs from "../assets/images/brands/GraphQL.svg";
import vueJsvg from "../assets/images/brands/Vue-js.svg";
import nuxt from "../assets/images/brands/nuxt.svg";
import bootSvg from "../assets/images/brands/bootstrapsvg.svg";

const TechStack = () => {
  const params = {
    slidesPerView: 1,
    loop: true,
    speed: 1000,
    spaceBetween: 30,
    autoplay: {
      delay: 3000,
      disableOnInteraction: false,
    },
    // Responsive breakpoints
    breakpoints: {
      1499: {
        slidesPerView: 1,
      },

      991: {
        slidesPerView: 1,
      },

      767: {
        slidesPerView: 1,
      },

      575: {
        slidesPerView: 1,
      },

      376: {
        slidesPerView: 1,
      },
    },
  };

  return (
    <section className="service-one" id="molecules">
      <div className="brand-one">
        <div className="block-title text-center">
          <h2 className="block-title__title">
            Software
            <span> Molecule</span> Created
          </h2>
        </div>

        <div className="container">
          <div className="brand-one__carousel owl-carousel owl-theme">
            <Swiper {...params}>
              <div className="item">
                <img src={techstack} alt="" />
              </div>
           
            </Swiper>
          </div>
        </div>
      </div>
    </section>
  );
};
export default TechStack;

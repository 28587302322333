import React from "react";
import { FaDiscord } from "react-icons/fa";

const Subscribe = () => {
  return (
    <section className="mailchimp-one">
      <div className="container">
        <div className="block-title text-center">
          <h2 className="block-title__title">
            Need <span>Assistance?</span> <br />
            join our discord server
          </h2>
        </div>
        <div className="footer-widget">
          <div className="site-footer__social">
            <a
              href="https://discord.gg/VUYatCGHtP"
              aria-label="social icon"
              aria-hidden="true"
              target="_blank"
            >
              <i className="fab fa-discord">
                <FaDiscord />
              </i>
            </a>
          </div>
        </div>
        {/* <form
          action="#"
          className="mailchimp-one__mc-form mc-form"
          data-url="#"
        >
          <label htmlFor="mc-email" className="sr-only">
            Add email
          </label>
          <input
            type="email"
            id="mc-email"
            className="formInput"
            placeholder="Email address"
          />
          <button type="submit">
            <i className="dimon-icon-right-arrow"></i>
          </button>
        </form> */}
        {/* <button >
            <i className="dimon-icon-right-arrow"></i>
          </button> */}
        <div className="mc-form__response"></div>
      </div>
    </section>
  );
};
export default Subscribe;

import React, { useEffect, useRef } from "react";
import lottie from "lottie-web";

import BannerMoc from "../assets/images/mocs/banner-moc-1-1.png";
import CodeiLogo from "../assets/images/resources/code-inspector-logo.png";

const Banner = () => {
  const container = useRef(null);
  useEffect(() => {
    lottie.loadAnimation({
      container: container.current,
      renderer: "svg",
      loop: true,
      autoplay: true,
      animationData: require("../assets/images/lotties/edu-i.json"),
    });
  }, []);

  return (
    <section className="banner-one" id="banner">
      <span className="banner-one__shape-1"></span>
      <span className="banner-one__shape-2"></span>
      <span className="banner-one__shape-3"></span>
      <span className="banner-one__shape-4"></span>
      <div className="container">
        {/* <div className="banner-one__moc"> */}
        <div className="banner-two__moc">
          <div className="lottiebanner2" ref={container}></div>
          {/* <img src={BannerMoc} alt="alter text" /> */}
        </div>

        <div className="row">
          <div className="col-xl-6 col-lg-8 col-sm-12">
            <div className="banner-one__content">
              <h3 className="banner-one__title">
                User reports <br />
                <span>Dashboard</span>
                <br /> solution
              </h3>

              <p className="banner-one__text navbar-brand">
              <img
                src={CodeiLogo}
               
                width="110"
                alt="code-inspector"
              />
                Automate your <a href="https://www.code-inspector.com/">Code-inspector</a> data
                
              </p>

              
              <a href="https://dashboard-beta.code-inspector.com/" className="banner-one__btn thm-btn ">
                <span>Try it </span>
              </a>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};
export default Banner;

import React from "react";
import { FaUsers } from "react-icons/fa";
import { BsClipboardData } from "react-icons/bs";
import { CgPerformance } from "react-icons/cg";
import { BiGitPullRequest } from "react-icons/bi";

const FeaturesEdu = () => {
  return (
    <section className="service-one" id="featuresedu">
      <div className="container">
        <div className="block-title text-center">
          <h2 className="block-title__title">
            Friendly reporting Dashboard
            <br /> <span>To Control, Manage and Report</span>
          </h2>
        </div>
        <div className="row">
          <div
            className="col-lg-3 col-md-6 col-sm-12 wow fadeInUp"
            data-wow-duration="1500ms"
          >
            <div className="service-one__single text-center">
              <div className="service-one__inner">
                <i className="service-one__icon">
                  <BsClipboardData />
                </i>
                <h3>
                  <a href="#none">
                    Manage your <br />
                    Team Metrics
                  </a>
                </h3>
                <p>
                  Make business decisions based in Code-inspector data Get all
                  your team Code Violations, Duplicates, Long functions and
                  Complex functions in one place
                </p>
              </div>
            </div>
          </div>
          <div
            className="col-lg-3 col-md-6 col-sm-12 wow fadeInDown"
            data-wow-duration="1500ms"
          >
            <div className="service-one__single text-center">
              <div className="service-one__inner">
                <i className="service-one__icon">
                  <FaUsers />
                </i>
                <h3>
                  <a href="#none">Full USER management control</a>
                </h3>
                <p>
                  Enables you to easily access, manage Unlimited Admins, Users
                  and Participants to your needs
                </p>
              </div>
            </div>
          </div>
          <div
            className="col-lg-3 col-md-6 col-sm-12 wow fadeInUp"
            data-wow-duration="1500ms"
          >
            <div className="service-one__single text-center">
              <div className="service-one__inner">
                <i className="service-one__icon">
                  <CgPerformance />
                </i>
                <h3>
                  <a href="#none">Track Performance, Group by Rooms</a>
                </h3>
                <p>
                  In Rooms can invite a group of participants and get the
                  Code-inspector data in one place Create many Rooms and invite
                  Participants as you want
                </p>
              </div>
            </div>
          </div>
          <div
            className="col-lg-3 col-md-6 col-sm-12 wow fadeInDown"
            data-wow-duration="1500ms"
          >
            <div className="service-one__single text-center">
              <div className="service-one__inner">
                <i className="service-one__icon">
                  <BiGitPullRequest />
                </i>
                <h3>
                  <a href="#none">Get richer insights, Consolidated Results</a>
                </h3>
                <p>
                  Get each Participant results from Code-inspector. Code
                  Analysis, Technical Debt Analysis and Automated Code Reviews
                </p>

              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default FeaturesEdu;

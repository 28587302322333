import React, { useEffect, useRef } from "react";
import bgImage from "../assets/images/background/cta-one-bg.png";
import mocImage from "../assets/images/mocs/cta-moc-1-1.png";
import CodeiLogo from "../assets/images/resources/code-inspector-logo.svg";
import { BiCodeAlt, BiGitBranch } from "react-icons/bi";
import { AiOutlineCode } from "react-icons/ai";

const CallToActionEdu = () => {
  return (
    <section className="cta-one cta-oneh">
      <img src={bgImage} className="cta-one__bg" alt="alter text" />
      <div className="container">
        <img src={CodeiLogo} className="cta-one__moc" alt="alter text" />
        <div className="row justify-content-lg-end">
          <div className="col-lg-6">
            <div className="cta-one__content">
              <i className="cta-one__icon ">
                <AiOutlineCode />
                <BiCodeAlt />
                <BiGitBranch />
              </i>
              <div className="block-title text-left">
                <h2 className="block-title__title">
                  Backed by <span>code-inspector.com </span>
                </h2>
              </div>
              <div className="cta-one__text">
                <p>
                  Code Inspector is a code analysis platform that performs
                  automated code reviews on GitHub, GitLab, and Bitbucket. It is
                  free to use for public projects. Try it now!.
                </p>
              </div>
              <ul className="list-unstyled">
                <li>
                  <i className="fa fa-check"></i>Code Analysis with historical
                  analysis
                </li>
                <li>
                  <i className="fa fa-check"></i>Technical Debt Analysis
                </li>
                <li>
                  <i className="fa fa-check"></i>Automated Code Reviews
                </li>
                <li>
                  <i className="fa fa-check"></i>Integration with multiple
                  platforms
                </li>
              </ul>
              <a href="https://www.code-inspector.com/" className="thm-btn">
                <span>Try Code Inspector Today!</span>
              </a>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};
export default CallToActionEdu;

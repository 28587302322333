import React from "react";
import Layout from "../components/Layout";
import NavTwo from "../components/NavTwo";
import PageHeader from "../components/PageHeader";
import News from "../components/News";
import Footer from "../components/Footer";
import CodeInspector from "../components/CodeInspector";
import NavOne from "../components/NavOne";
import Banner from "../components/Banner";
import Video from "../components/Video";
import Brands from "../components/Brands";
import Screenshots from "../components/Screenshots";
import Subscribe from "../components/Subscribe";
import TeamPage from "../components/TeamPage";
import CallToAction from "../components/CallToAction";
import Features from "../components/FeaturesEdu";
import TechStack from "../components/TechStack";
import CallToActionEdu from "../components/CallToActionEdu";
import CodeI from "../components/CodeInsPage";

const Portfolio = () => (
  <Layout
    pageTitle="Edu-inspector a friendly Code-inspector dashboard
  for Academies and Companies"
  >
    <NavOne />
    <Banner />
    <Features />
   
    <Video />
    <TechStack />
    <TeamPage />
    <Screenshots />
    <CallToActionEdu />
    <Subscribe />
    <Footer />
  </Layout>
);

export default Portfolio;

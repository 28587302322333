import React, { Component } from "react";
import ModalVideo from "react-modal-video";
import videoBG from "../assets/images/background/video-one-bg.png";
import videoImage from "../assets/images/resources/video-1-1.jpg";
import covervideo from "../assets/images/resources/cover-video.png";
import ReactPlayer from "react-player";
export default class Video extends Component {
  constructor() {
    super();
    this.state = {
      isOpen: false,
    };
  }

  // openModal = () => {
  //   this.setState({ isOpen: true });
  // };

  render() {
    return (
      <section className="video-one" id="video">
        {/* <img src={videoBG} alt="alter text" className="video-one__bg" /> */}
        <div className="block-title text-center">
          <h2 className="block-title__title">
          Enjoy the <span>Edu-inspector</span> experience

          </h2>
        </div>
        <div className="container wow fadeInUp" data-wow-duration="1500ms">
          <div className="player-wrapper">

            <ReactPlayer
              className="react-player"
              url="https://youtu.be/rJaiYxbCkjc"
              width="100%"
              height="100%"
            />

            {/* <iframe
              className="embed-responsive-item"
              width="1131"
              height="636"
              src="https://www.youtube.com/embed/rJaiYxbCkjc"
              title="YouTube video player"
              frameborder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowfullscreen
            ></iframe> */}
            {/* <img src={covervideo} alt="" /> */}
            {/* <div className="video-one__content">
              <ModalVideo
                channel="youtube"
                isOpen={this.state.isOpen}
                videoId="rJaiYxbCkjc"
                onClose={() => this.setState({ isOpen: false })}
              />
              <div
                onClick={this.openModal}
                onKeyDown={this.openModal}
                role="button"
                tabIndex="-1"
                className="video-popup video-one__btn"
              >
                <i className="fa fa-play"></i>
              </div>

              <h2 className="video-one__title">
                Watch Our <span>Presentation</span> <br /> Video.
              </h2>
            </div> */}
          </div>
        </div>
      </section>
    );
  }
}
